import Cookies from 'universal-cookie';
import { logOut } from './Authentication';

const cookieName = "AuthCurrentCompany";

/* Create a current compnay cookie */
export async function createCurrentCompanyCookie(rawCompany) {
    //Send company to back end for verification/tokinization
    const response = await fetch('CurrentCompany/CreateCurrentCompanyToken?company=' + rawCompany, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
    const token = await response.json();

    //Save token cookie
    if (token != "") {
        var d = new Date();
        d.setTime(d.getTime() + (7 * (24 * 60 * 60 * 1000)))//90 Days -> (days * milliseconds per day)
        var expires = "expires=" + d.toUTCString();

        const cookies = new Cookies();
        cookies.set(cookieName, token, expires);
    }

    return token;
}

/* Remove the current company cookie */
export function removeCurrentCompanyCookie() {
    const cookies = new Cookies();
    cookies.remove(cookieName);
}

/* Return the current company cookie token */
export function getCurrentCompanyToken() {
    const cookies = new Cookies();
    var token = cookies.get(cookieName);
    if (token == null || token == "" || token == undefined) {
        if (window.location.pathname != "/company-selection") {
            logOut();
        }
    }
    else {
        return token;
    }
}

/* Return current company name */
export async function getCurrentCompanyName() {
    const cookies = new Cookies();
    var token = cookies.get(cookieName);

    const response = await fetch('CurrentCompany/GetCurrentCompanyName?companyToken=' + token, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
    const companyName = await response.json();
    return companyName;
}

