import React, { Component } from 'react';
import * as auth from "../../Authentication.js";
import "./login.css";

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.state = {
            company: "DonateQuest"
        };
    }

    authenticateLogin = function (e) {
        alert('test');
        e.preventDefault();
    }

    handleLogin(event) {
        auth.authenticateLogin(document.getElementById("login").children[0]);
        event.preventDefault();
    }

    handleChangePassword(event) {
        auth.verifyPasswordUpdate(document.getElementById("changePassword").children[0]);
        event.preventDefault();
    }

    handleForgotPassword(event) {
        auth.updateDisplayForm("login", "forgotPasswordRequest");
        event.preventDefault();
    }

    handleTwoFactor(event) {
        auth.verifyTwoFactorCode(document.getElementById("twoFactor").children[0])
        event.preventDefault();
    }

    resendTwoFactorCode(event) {
        auth.resendTwoFactorCode();
        event.preventDefault();
    }

    handleForgotPasswordRequest(event) {
        auth.forgotPasswordRequest(document.getElementById("forgotPasswordRequest").children[0])
        event.preventDefault();
    }

    handleForgotPasswordChange(event) {
        auth.forgotPasswordChange(document.getElementById("forgotPasswordChange").children[0])
        event.preventDefault();
    }

    render() {
        return (
            <div className="container login-container">
                
                <div className="header" id="header">
                    Portal Login 
                </div>
            
                <div className="form-step" id="login">
                    <form onSubmit={this.handleLogin}>
                        <input type="hidden" name="company" id="company" value={this.state.company} />

                        <p className="error-message" id="loginError" />

                        <div className="input-container form-fields">
                            <i className="fa fa-envelope icon" />
                            <input type="text" name="email" id="email" required="required" className="form-control input-field" placeholder="Username" />
                        </div>

                        <div className="input-container form-fields">
                            <i className="fa fa-lock icon"></i>
                            <input type="password" name="password" id="password" required="required" className="form-control input-field" placeholder="Password" />
                        </div>

                        <input className="btn btn-primary form-fields-login" type="submit" name="submit" value="Log In" />
                        <p className="assist-link">
                            <a href="#" onClick={this.handleForgotPassword}>Don't remember your password?</a>
                        </p>
                    </form>
                </div>

                <div className="form-step" id="changePassword" style={{ display: "none" }}>
                    <form onSubmit={this.handleChangePassword}>
                        <input type="hidden" name="company" id="company" value={this.state.company} />
                        <input type="hidden" name="userId" id="changePasswordUserId" value="0" />

                        <p className="error-message" id="changePasswordError"></p>

                        <div className="input-container form-fields">
                            <i className="fa fa-lock icon"></i>
                            <input type="text" name="oldPassword" id="oldPassword" required="required" className="form-control input-field" placeholder="Old Password" />
                        </div>

                        <div className="input-container form-fields">
                            <i className="fa fa-lock icon"></i>
                            <input type="text" name="newPassword" id="newPassword" required="required" className="form-control input-field" placeholder="New Password" minLength="8" maxLength="20" />
                        </div>

                        <div className="input-container form-fields">
                            <i className="fa fa-check-circle icon"></i>
                            <input type="text" name="confirmPassword" id="confirmPassword" required="required" onInput={(event) => { auth.matchPassword(event.target) }} className="form-control input-field" placeholder="Confirm Password" />
                        </div>

                        <p className="assist-link"></p>

                        <input className="btn btn-primary form-fields-login" type="submit" name="submit" value="Log In" />
                    </form>
                </div>

                <div className="form-step" id="twoFactor" style={{ display: "none" }}>
                    <form onSubmit={this.handleTwoFactor}>
                        <input type="hidden" name="company" id="company" value={this.state.company} />
                        <input type="hidden" name="userId" id="twoFactorUserId" value="0" />

                        <p className="error-message" id="twoFactorError"></p>

                        <label className="input-label">Enter the number that has been texted to your cell phone below.</label>
                        <div className="input-container form-fields">
                            <i className="fa fa-commenting icon"></i>
                            <input type="number" min="100000" max="999999" name="code" id="code" required="required" className="form-control input-field" placeholder="######" />
                        </div>
                        <input className="btn btn-primary form-fields-login" type="submit" name="submit" value="Confirm" />
                        <p className="assist-link">
                            <a href="#" onClick={this.resendTwoFactorCode}>Resend Code</a>
                        </p>
                    </form>
                </div>
                <div className="form-step" id="forgotPasswordRequest" style={{ display: "none" }}>
                    <form onSubmit={this.handleForgotPasswordRequest}>
                        <input type="hidden" name="company" id="company" value={this.state.company} />
                        <p className="error-message" id="forgotPwRequestError"></p>
                        <div className="input-container">
                            <i className="fa fa-envelope icon"></i>
                            <input type="text" name="email" id="forgotPasswordEmail" required="required" className="form-control input-field" placeholder="Username" />
                        </div>
                        <input className="btn btn-primary form-fields-login" type="submit" name="submit" value="Submit" />
                        <p className="assist-link">
                            <a href="/" onClick={(event) => { auth.updateDisplayForm("forgotPasswordRequest", "login"); event.preventDefault(); }} >Back to Login</a>
                        </p>
                    </form>
                </div>
                <div className="form-step" id="forgotPasswordChange" style={{ display: "none" }}>
                    <form onSubmit={this.handleForgotPasswordChange}>
                        <input type="hidden" name="company" id="company" value={this.state.company} />
                        <input type="hidden" name="email" id="forgotPasswordChangeEmail" value="" />
                        <p className="error-message" id="forgotPwChangeError"></p>
                        <label className="input-label">Enter the number that has been texted to your cell phone below.</label>
                        <div className="input-container">
                            <i className="fa fa-commenting icon"></i>
                            <input type="number" min="100000" max="999999" name="code" id="code" required="required" className="form-control input-field" placeholder="######" />
                        </div>
                        <div className="input-container">
                            <i className="fa fa-lock icon"></i>
                            <input name="password" type="password" id="forgotNewPassword" required="required" className="form-control input-field" placeholder="New Password" minLength="8" maxLength="20" />
                        </div>
                        <div className="input-container">
                            <i className="fa fa-check-circle icon"></i>
                            <input type="password" name="confirmPassword" id="confirmPassword" required="required" onInput={(event) => { auth.matchNewPassword(event.target) }} className="form-control input-field" placeholder="Confirm Password" />
                        </div>
                        <input className="btn btn-primary form-fields-login" type="submit" name="submit" value="Submit" />
                        <p className="assist-link">
                            <a href="/" onClick={(event) => { auth.updateDisplayForm("forgotPasswordChange", "login"); event.preventDefault(); }}>Back to Login</a>
                        </p>
                    </form>
                </div>
            </div>
        );
    }
}
