import React, { Component } from 'react';
import { getCurrentCompanyToken } from '../../../CurrentCompanyServices';

export class GeneralSettings extends Component {
    static displayName = GeneralSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            company: null,
            requestFormSettings: null
        };
    }

    async componentDidMount() {
        this.getComponentData();
    }

    handleCompanyChange = (e) => {
        let company = this.state.company;

        company[e.target.name] = e.target.value;

        this.setState({ company: company });
    }

    handleRequestFormSettingsChange = (e) => {
        let requestFormSettings = this.state.requestFormSettings;

        if (e.target.name == "requestPerMonthLimit") {
            requestFormSettings[e.target.name] = !isNaN(e.target.value) ? parseInt(e.target.value) : 0;
        }
        else {
            requestFormSettings[e.target.name] = e.target.value;
        }

        this.setState({ requestFormSettings: requestFormSettings });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let token = getCurrentCompanyToken();

        let company = this.state.company;
        let requestFormSettings = this.state.requestFormSettings;

        let generalSettingsData = {
            companyToken: token,
            company: company,
            requestFormSettings: requestFormSettings
        };

        fetch('Settings/UpdateGeneralSettings', {
            method: 'POST',
            body: JSON.stringify(generalSettingsData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    alert("Saved");
                }
                else {
                    alert("Error");
                }
            }
        );
    }

    render() {
        let adminEmail;
        if (this.state.company != null) {
            let company = this.state.company;
            adminEmail = company.adminEmail;
        }

        let requestPerMonthLimit;
        if (this.state.requestFormSettings != null) {
            let requestFormSettings = this.state.requestFormSettings;
            requestPerMonthLimit = requestFormSettings.requestPerMonthLimit;
        }

        return (
            <div className='general-settings-container'>
                <div className='form-title'>General</div>

                <form onSubmit={this.handleSubmit} className='general-settings-form settings-form dual-grid--offset'>
                    <label>Admin Email: </label>
                    <input name='adminEmail' type='text' defaultValue={adminEmail} onChange={this.handleCompanyChange} required />

                    <label>Request Per 30 Days Limit: </label>
                    <input name='requestPerMonthLimit' type='number' min='0' step='1' defaultValue={requestPerMonthLimit} onChange={this.handleRequestFormSettingsChange} required />

                    <div className='dual-grid__1-2 center-grid'>
                        <button type='submit' className='form-submit-btn'>Save</button>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let token = getCurrentCompanyToken();
        const response = await fetch('Settings/GetGeneralSettings?companyToken=' + token, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        this.setState({
            isReady: true,
            company: data.company,
            requestFormSettings: data.requestFormSettings
        });
    }
}

export default GeneralSettings;
