import React, { Component } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Input, Label, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export class HtmlEditorField extends Component {
    static displayName = HtmlEditorField.name;

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            buttonModal: false,
            button: {
                buttonText: null,
                buttonLink: null
            }
        };
       
        this.toggleButtonForm = this.toggleButtonForm.bind(this);
        this.createButton = this.createButton.bind(this);
        this.handleButtonChange = this.handleButtonChange.bind(this);
    }

    componentDidMount() {
        
    }

    toggleButtonForm() {
        let newButton = this.state.button;
        newButton["buttonText"] = null;
        newButton["buttonLink"] = null;

        this.setState({
            button: newButton,
            buttonModal: !this.state.buttonModal
        });
    }

    handleButtonChange(e) {
        let name = e.target.name;
        let value = e.target.value;

        let newButton = this.state.button;
        newButton[name] = value;
        this.setState({ button: newButton });
    }

    createButton(e) {
        let button = this.state.button;

        if (button.buttonText != null && button.buttonText != "" && button.buttonLink != null && button.buttonLink != "") {
            window.tinymce.activeEditor.execCommand('mceInsertContent', false, "<a href='" + button.buttonLink + "'><button style='padding:10px 20px'>" + button.buttonText + "</button></a>");
        }
        else {
            alert("All fields must be filled out to create a button");
        }

        this.toggleButtonForm();
    }

    handleChange = (content, editor) => {
        let responseContent = content;

        let nameValPair = { name: this.props.name, value: content };
        responseContent = { target: nameValPair };

        this.props.handleEditorChange(responseContent);
    }

    render() {
        let content = this.props.content;

        let buttonText = this.state.button.buttonText;
        let buttonLink = this.state.button.buttonLink;

        const buttonModal = this.state.buttonModal;
        const toggleButtonForm = () => { this.toggleButtonForm() }
        function toggleButton() {
            { toggleButtonForm() }
        }

        return (
            <div>
                <Editor
                    apiKey='8xl6y8osi00yu2trkucfo3itp7jixxiimvk7f8quz69h8etg'
                    value={content}
                    init={{
                        height: 500,
                        menubar: false,
                        fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt",
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount',
                            'media',
                            'code',
                            'hr'
                        ],
                        toolbar:
                            'undo redo | \
                            fontselect | \
                            fontsizeselect | \
                            formatselect | \
                            bold italic underline strikethrough | \
                            subscript superscript | \
                            forecolor backcolor | \
                            alignleft aligncenter alignright alignjustify lineHeight | \
                            bullist media hr | \
                            code | \
                            removeformat',
                        setup: function (editor) {
                            //editor.ui.registry.addButton('customButton', {
                            //    icon: 'template',
                            //    onAction: () => { toggleButton() }
                            //});
                        },
                    }}
                    onEditorChange={this.handleChange}
                />

                <Modal style={{ maxWidth: "900px" }} isOpen={this.state.buttonModal} toggle={this.toggleButtonForm} className={this.props.className}>
                    <ModalHeader toggle={this.toggleButtonForm}>Button</ModalHeader>
                    <ModalBody>
                        <input name='buttonText' value={buttonText} type='text' style={{ marginBottom: "5px" }} placeholder='Text' onChange={this.handleButtonChange} />
                        <input name='buttonLink' value={buttonLink} type='text' style={{ marginBottom: "5px" }} placeholder='Link' onChange={this.handleButtonChange} />
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' onClick={this.createButton}>Create</Button>
                        <Button color='secondary' onClick={this.toggleButtonForm}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default HtmlEditorField;