import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLoggedInUser } from '../Authentication';
import { getCurrentCompanyToken } from '../CurrentCompanyServices';
import AdminDashboard from '../Admin/AdminDashboard/AdminDashboard';

// This is for routes that are only allowed for logged in TracSoft Users
export default class AuthorizeTSAdminRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            loggedInUser: null,
            currentCompanyToken: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        if (!this.state.isReady) {
            return <div></div>;
        }
        else {
            const redirectUrl = '/login';
            const { component: Component, ...rest } = this.props;
            const navbarParentUrl = this.props.navbarParentUrl;

            return <Route {...rest}
                render={(props) => {
                    if (this.state.loggedInUser != null && this.state.loggedInUser.company == "TracSoft") {
                        let path = props.match.path;

                        if ((this.state.currentCompanyToken == null || this.state.currentCompanyToken == "" || this.state.currentCompanyToken == undefined) && path != "/company-selection") {
                            return <Redirect to='/company-selection' />
                        }
                        else {
                            return <AdminDashboard component={<Component {...props} />} navbarParentUrl={navbarParentUrl} /> // Continue path
                        }
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }}
            />
        }
    }

    async getComponentData() {
        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        let currentCompanyToken = getCurrentCompanyToken();
        //console.log(currentCompanyToken);

        this.setState({
            isReady: true,
            loggedInUser: loggedInUser,
            currentCompanyToken: currentCompanyToken
        });
    }
}
