import React, { Component } from 'react';
import { getCurrentCompanyToken } from '../../../CurrentCompanyServices';

export class DesignationSettings extends Component {
    static displayName = DesignationSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            designations: [],
            currentDesignation: null
        };
    }

    async componentDidMount() {
        this.getComponentData();
    }

    handleDesignationChange = (e) => {
        let currentDesignation = this.state.currentDesignation;

        if (e.target.name == "price") {
            currentDesignation[e.target.name] = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0;
        }
        else {
            currentDesignation[e.target.name] = e.target.value;
        }

        this.setState({ currentDesignation: currentDesignation });
    }

    addDesignation = (e) => {
        e.preventDefault();
        let token = getCurrentCompanyToken();
        let currentDesignation = this.state.currentDesignation;

        if (currentDesignation.name != null && currentDesignation.name != "" && currentDesignation.name != undefined &&
            currentDesignation.price != null && currentDesignation.price != undefined && !isNaN(currentDesignation.price) && parseFloat(currentDesignation.price) >= 0) {

            let newDesignationData = {
                companyToken: token,
                currentDesignation: currentDesignation
            };

            fetch('Settings/AddDesignation', {
                method: 'POST',
                body: JSON.stringify(newDesignationData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (!isNaN(data)) {
                        this.getComponentData();
                    }
                    else {
                        alert(data);
                    }
                }
            );
        }
        else {
            alert("Name and price are required to add designation");
        }
    }

    removeDesignation = (designationId) => {
        let confirmRemoval = window.confirm("Are you sure you want to remove this designation?");
        if (confirmRemoval) {
            let token = getCurrentCompanyToken();

            let removeDesignationData = {
                companyToken: token,
                currentDesignationId: designationId
            };

            fetch('Settings/RemoveDesignation', {
                method: 'POST',
                body: JSON.stringify(removeDesignationData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (!isNaN(data)) {
                        this.setState({ designations: [] });
                        this.getComponentData();
                    }
                    else {
                        alert(data);
                    }
                }
            );
        }
    }

    render() {
        let designationRows = [];

        for (var i = 0; i < this.state.designations.length; i++) {
            let designation = this.state.designations[i];
            designationRows.push(
                <>
                    <label key={"designationNameLabel" + i}>Name: </label>
                    <input key={"designationNameInput" + i} type='text' defaultValue={designation.name} disabled required />
                    <label key={"designationPriceLabel" + i}>Price: </label>
                    <input key={"designationPriceInput" + i} type='number' min='0' step='0.01' defaultValue={designation.price} disabled required />
                    <span key={"designationRemove" + i} className='manage-row-btn remove-designation-btn' onClick={this.removeDesignation.bind(this, designation.id)}>REMOVE</span>
                </>
            );
        }

        let nullDesignationMessage;
        if (designationRows.length == 0) {
            nullDesignationMessage = <div className='warning-message'>*Please add at least one designation. Without a designation your donation request form will not be submittable.</div>;
        }

        if (this.state.currentDesignation != null) {
            designationRows.push(
                <>
                    <label key={"newDesignationNameLabel"}>Name: </label>
                    <input key={"newDesignationNameInput"} name='name' defaultValue={this.state.currentDesignation.name} type='text' onChange={this.handleDesignationChange} />
                    <label key={"newDesignationPriceLabel"}>Price: </label>
                    <input key={"newDesignationPriceInput"} name='price' value={this.state.currentDesignation.price} type='number' onChange={this.handleDesignationChange} min='0' step='0.01' />
                    <span key={"newDesignationAdd"} className='manage-row-btn add-designation-btn' onClick={this.addDesignation}>ADD</span>
                </>
            );
        }

        return (
            <div className='request-form-designations-container'>
                <div className='form-title'>Request Designations</div>

                {nullDesignationMessage}

                <div className='designation-settings-form settings-form dual-grid--offset'>
                    {designationRows}
                </div>
            </div>
        );
    }

    async getComponentData() {
        let token = getCurrentCompanyToken();
        const response = await fetch('Settings/GetDesignationSettings?companyToken=' + token, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        this.setState({
            isReady: true,
            designations: data.requestDesignations,
            currentDesignation: data.currentDesignation
        });
    }
}

export default DesignationSettings;
