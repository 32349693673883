//This page will manage the settings forms with tabs
import React, { Component } from 'react';
import './settings.css';

import GeneralSettings from './Forms/GeneralSettings';
import BudgetSettings from './Forms/BudgetSettings';
import EmailSettings from './Forms/EmailSettings';
import DesignationSettings from './Forms/DesignationSettings';

export class Settings extends Component {
    static displayName = Settings.name;

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            currentTab: <GeneralSettings />,
            currentTabName: "General"
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleTabChange = (tabName) => {
        let currentTab;
        switch (tabName) {
            case "General":
                currentTab = <GeneralSettings />;
                break;
            case "Budget":
                currentTab = <BudgetSettings />;
                break;
            case "Email":
                currentTab = <EmailSettings />;
                break;
            case "Request Designations":
                currentTab = <DesignationSettings />;
                break;
            default:
                currentTab = <GeneralSettings />;
                break;
        }

        this.setState({
            currentTab: currentTab,
            currentTabName: tabName
        });
    }

    render() {
        let currentTab = this.state.currentTab;
        let currentTabName = this.state.currentTabName;

        let contents;
        if (this.state.isReady) {
            contents = <>
                <div className='tab-row'>
                    <div className={currentTabName == "General" ? "tab current-tab" : "tab"} onClick={this.handleTabChange.bind(this, "General")}>General</div>
                    <div className={currentTabName == "Budget" ? "tab current-tab" : "tab"} onClick={this.handleTabChange.bind(this, "Budget")}>Budget</div>
                    <div className={currentTabName == "Email" ? "tab current-tab" : "tab"} onClick={this.handleTabChange.bind(this, "Email")}>Email</div>
                    <div className={currentTabName == "Request Designations" ? "tab current-tab" : "tab"} onClick={this.handleTabChange.bind(this, "Request Designations")}>Request Designations</div>
                </div>

                <div>
                    {currentTab}
                </div>
            </>;
        }

        return (
            <div className='settings-container'>
                <div className='page-title'>Settings</div>

                {contents}
            </div>
        );
    }

    async getComponentData() {

        this.setState({
            isReady: true
        });
    }
}

