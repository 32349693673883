//This is a dynamic form for donation requests
import React, { Component } from 'react';
import reCaptchaData from '../../ReCaptchaData';
import ReCAPTCHA from "react-google-recaptcha";
import './forms.css';

export class RequestDonation extends Component {
    static displayName = RequestDonation.name;

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            captchaToken: null,
            request: null,
            requestSettingsData: null
        };

        this.friendlyUrl = props.match.params.friendlyUrl;
    }

    async componentDidMount() {
        this.getComponentData();
    }

    handleChange = (e) => {
        let request = this.state.request;
        request[e.target.name] = e.target.value;
        this.setState({ request: request });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        document.body.style.cursor = 'wait';

        let request = this.state.request;

        let requestData = {
            request: request,
            friendlyUrl: this.friendlyUrl,
            requestSettingsData: null
        };

        //Verify captcha before submit
        if (this.state.captchaToken != null) {
            fetch('Captcha/IsReCaptchValid?captchaToken=' + this.state.captchaToken, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (data == true) {
                        fetch('Public/CreateRequest', {
                            method: 'POST',
                            body: JSON.stringify(requestData),
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }).then(
                            response => response.json()
                        ).then(
                            data => {
                                if (!isNaN(data)) {
                                    alert("Your request has been submitted. You will receive an answer by email once the request is reviewed.");
                                    window.location.reload();
                                    document.body.style.cursor = 'default';
                                }
                                else {
                                    alert(data);
                                    document.body.style.cursor = 'default';
                                }
                            }
                        );
                    }
                    else {
                        alert("reCaptcha verification failed");
                        document.body.style.cursor = 'default';
                    }
                }
            );
        }
        else {
            alert("reCaptcha verification failed");
            document.body.style.cursor = 'default';
        }
    }

    onCaptchaChange = (value) => {
        if (value) {
            this.setState({ captchaToken: value });
        }
        else {
            this.setState({ captchaToken: null });
        }
    }

    render() {
        let requestedDate;
        let firstName;
        let lastName;
        let email;
        let designation;
        let comments;
        if (this.state.request != null) {
            let request = this.state.request;
            requestedDate = request.requestedDate;
            firstName = request.firstName;
            lastName = request.lastName;
            email = request.email;
            designation = request.designation;
            comments = request.comments;
        }

        let companyTitle;
        let header;
        let footer;
        let designationOptions = [];
        if (this.state.requestSettingsData != null) {
            let requestSettingsData = this.state.requestSettingsData;
            let designationSet = (requestSettingsData.requestDesignations != null && requestSettingsData.requestDesignations != "") ? requestSettingsData.requestDesignations : [];

            for (var i = 0; i < designationSet.length; i++) {
                let designationText = designationSet[i].name;
                let designationValue = designationSet[i].id;
                let designationKey = "designation" + i;

                let designationOption = <option key={designationKey} value={designationValue}>{designationText}</option>;
                designationOptions.push(designationOption);
            }

            if (requestSettingsData.companyName != null && requestSettingsData.companyName != "") {
                companyTitle = " from " + requestSettingsData.companyName;
            }

            //Header section
            //if (requestSettingsData.showHeader) {
            //    let companyLogo = requestSettingsData.logoUrl != null && requestSettingsData.logoUrl != "" ? <img src={requestSettingsData.logoUrl} alt="Company Logo" className='form-header-img' /> : "";
            //    header = <div className='form-header'>
            //        {companyLogo}
            //        <span className='company-header-name'>{requestSettingsData.companyName}</span>
            //    </div>;
            //}

            // Footer section
            footer = <div className='form-footer'>
                <span>Powered By <a href='https://tracsoft.com/'>TracSoft</a></span>
            </div>;
        }

        //Get the minimum allowed request date
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth().toString().length === 1 ? '0' + (now.getMonth() + 1).toString() : now.getMonth() + 1;
        let date = now.getDate().toString().length === 1 ? '0' + (now.getDate()).toString() : now.getDate();

        let requestDateMin = year + '-' + month + '-' + date;

        if (this.state.isReady) {
            return (
                <div className='create-request-container form-outer-container'>
                    <form onSubmit={this.handleSubmit} className='dual-grid--offset form-container'>
                        <div className='dual-grid__1-2 center-grid'>
                            <div className='form-title'>Request Donation {companyTitle}</div>
                        </div>
                        
                        <label>First Name: </label>
                        <input name='firstName' type='text' defaultValue={firstName} onChange={this.handleChange} required />

                        <label>Last Name: </label>
                        <input name='lastName' type='text' defaultValue={lastName} onChange={this.handleChange} required />

                        <label>Email: </label>
                        <input name='email' type='text' defaultValue={email} onChange={this.handleChange} required />

                        <label>Donation Type: </label>
                        <select name='designation' value={designation} onChange={this.handleChange} required >
                            <option key='designationSelect' value=''>--Select--</option>
                            {designationOptions}
                        </select>

                        <label>Event/Require Date: </label>
                        <input name='requestedDate' type='date' min={requestDateMin} defaultValue={requestedDate} onChange={this.handleChange} required />

                        <label>Comments: </label>
                        <textarea name='comments' rows="4" value={comments} onChange={this.handleChange}></textarea>

                        <label></label>
                        <div id="reCaptchaWrapper">
                            <ReCAPTCHA sitekey={reCaptchaData.getSiteKey()} onChange={this.onCaptchaChange} />
                        </div>

                        <div className='dual-grid__1-2 center-grid'>
                            <button type='submit' className='form-submit-btn'>Submit</button>
                        </div>
                    </form>

                    {footer}
                </div>
            );
        }
        else {
            return (
                <div></div>  
            );
        }
    }

    async getComponentData() {
        let friendlyUrl = this.friendlyUrl;
        if (friendlyUrl != null && friendlyUrl != undefined && friendlyUrl != "") {
            const response = await fetch('Public/GetRequestData?friendlyUrl=' + friendlyUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            });

            const data = await response.json();

            if (data.requestSettingsData != null) {
                this.setState({
                    isReady: true,
                    request: data.request,
                    requestSettingsData: data.requestSettingsData
                });
            }
        }
    }
}