//This is where TracSoft users will be able to select a current company
import React, { Component } from 'react';
import './companyselection.css';
import { MDBDataTable } from 'mdbreact';
import { createCurrentCompanyCookie } from '../../CurrentCompanyServices';

export class CompanySelection extends Component {
    static displayName = CompanySelection.name;

    constructor(props) {
        super(props);
        this.state = {
            companies: []
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    async setCurrentCompany(companyName) {
        let token = await createCurrentCompanyCookie(companyName);
        if (token != null && token != "") {
            window.location = "/requests";
        }
    }

    render() {

        let tableRows = [];
        for (var i = 0; i < this.state.companies.length; i++) {
            let companyData = this.state.companies[i];
            let company = companyData.company;

            let requestFormLink = "/" + companyData.requestFormLink;

            let row = {
                company: <div className='txt-table-link' alt='link to company' title='Select Company' onClick={this.setCurrentCompany.bind(this, company.companyName)}>{company.companyName}</div>,
                requestForm: <a href={requestFormLink} target="_blank">{requestFormLink}</a>
            };
            tableRows.push(row);
        }

        let table = {
            columns: [
                { label: 'Company', field: 'company' },
                { label: 'Request Form', field: 'requestForm' }
            ],
            rows: tableRows
        };

        return (
            <div className='company-selection-container'>
                <div className='page-title'>Company Selection</div>

                <div className='table-container'>
                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>  
        );
    }

    async getComponentData() {
        const response = await fetch('Admin/GetCompaniesData', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        this.setState({
            companies: data
        });
    }
}
