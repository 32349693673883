import React, { Component } from 'react';
import { logOut, getLoggedInUser } from "../../Authentication";
import { getCurrentCompanyName } from "../../CurrentCompanyServices";
import './admindashboard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';

export class AdminDashboard extends Component {
    static displayName = AdminDashboard.name;

    constructor(props) {
        super(props);
        this.state = {
            loggedInUser: null,
            currentCompanyName: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    handleLogOut = (e) => {
        e.preventDefault();

        logOut();
    }

    render() {
        let navbarParentUrl = this.props.navbarParentUrl;
        let loggedInUser = this.state.loggedInUser;

        let userCompany = this.state.currentCompanyName == null || this.state.currentCompanyName == "" ? "DonateQuest" : this.state.currentCompanyName;

        // General navbar links
        let companyName = <h1 className='company-name'>{userCompany}</h1>;
        let settings;
        let requests;
        if (loggedInUser != null && (this.state.currentCompanyName != null && this.state.currentCompanyName != "")) {
            companyName = <h1 className='company-name'><a href='/requests' title='Requests'>{userCompany}</a></h1>
            settings = <a href='/settings' className={navbarParentUrl == 'settings' ? 'settings-cog selected-parent-page' : 'settings-cog'} title='Settings'><FontAwesomeIcon icon={faCog} className="icon" /></a>;
            requests = <a href='/requests' className={navbarParentUrl == 'requests' ? 'selected-parent-page' : ''} title='Requests'>Requests</a>;
        }

        // TracSoft only navbar links
        let companySelection;
        if (loggedInUser != null && loggedInUser.company == "TracSoft") {
            companySelection = <a href='/company-selection' className={navbarParentUrl == 'company-selection' ? 'selected-parent-page' : ''} title='Company Selection'>Company Selection</a>;
        }

        return (
            <div className='admin-page-container'>
                <div className='navbar-container'>
                    <div id='topNav' className='top-nav'>
                        <div className='nav-contents'>
                            {companyName}

                            <div className='right-nav'>
                                <span className="logout" title='Logout' alt='logout' onClick={this.handleLogOut}>Log Out</span>

                                {settings}
                                {requests}

                                {companySelection}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='page-content-wrapper'>
                    {this.props.component}
                </div>
            </div>  
        );
    }

    async getComponentData() {
        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        let currentCompanyName = await getCurrentCompanyName();

        this.setState({
            loggedInUser: loggedInUser,
            currentCompanyName: currentCompanyName
        });
    }
}

export default AdminDashboard;
