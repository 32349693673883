import React, { Component } from 'react';
import { getCurrentCompanyToken } from '../../../CurrentCompanyServices';

export class BudgetSettings extends Component {
    static displayName = BudgetSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            company: null,
            monthlyBudgets: []
        };
    }

    async componentDidMount() {
        this.getComponentData();
    }

    handleCompanyChange = (e) => {
        let company = this.state.company;

        if (e.target.name == "monthlyBudget") {
            company[e.target.name] = !isNaN(e.target.value) ? parseFloat(e.target.value) : 0
        }
        else {
            company[e.target.name] = e.target.value;
        }

        this.setState({ company: company });
    }

    handleMonthlyBudgetChange = (e) => {
        let monthlyBudgets = this.state.monthlyBudgets;
        let monthlyBudgetId = e.target.name;
        let value = e.target.value != null && e.target.value != "" && e.target.value != undefined ? parseFloat(e.target.value) : 0;

        for (var i = 0; i < monthlyBudgets.length; i++) {
            if (monthlyBudgets[i].id == monthlyBudgetId) {
                monthlyBudgets[i].budget = value;
            }
        }

        this.setState({ monthlyBudgets: monthlyBudgets });
    }

    handleUniqueBudgetSwitch = (e) => {
        let isChecked = e.target.checked;
        let budgetId = e.target.dataset.budgetid;
        let monthlyBudgets = this.state.monthlyBudgets;

        let inputField = document.getElementById(budgetId);
        if (inputField != null) {
            inputField.value = 0.00;
        }

        for (var i = 0; i < monthlyBudgets.length; i++) {
            if (monthlyBudgets[i].id == budgetId) {
                if (isChecked) {
                    monthlyBudgets[i].budget = -1;
                }
                else {
                    monthlyBudgets[i].budget = 0;
                }
            }
        }

        this.setState({ monthlyBudgets: monthlyBudgets });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        document.body.style.cursor = 'wait';

        let token = getCurrentCompanyToken();

        let company = this.state.company;
        let monthlyBudgets = this.state.monthlyBudgets;

        let budgetSettingsData = {
            companyToken: token,
            company: company,
            monthlyBudgets: monthlyBudgets
        }

        fetch('Settings/UpdateBudgetSettings', {
            method: 'POST',
            body: JSON.stringify(budgetSettingsData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    document.body.style.cursor = 'default';
                    alert("Saved");
                }
                else {
                    document.body.style.cursor = 'default';
                    alert("Error");
                }
            }
        );
    }

    render() {
        let monthWords = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        let monthlyBudget;
        if (this.state.company != null) {
            let company = this.state.company;
            monthlyBudget = company.monthlyBudget.toFixed(2);
        }

        let uniqueMonthlyBudgets = [];
        for (var i = 0; i < this.state.monthlyBudgets.length; i++) {
            let budget = this.state.monthlyBudgets[i];

            // Toggle for default "On/Off" 
            // If "On" disable the text box and make the number transparent, set the value to - 1
            // If "Off" enable the textbox and set the text to visible, set the value to 0 until the user changes the value manually
            // On page load, if the budget value is -1 set to the "On" state, otherwise set to the "Off" state 
            
            let budgetInputs = budget.budget == -1 ?
                <>
                    <input name={budget.id} className='budget-disabled' type="number" title={"Default: $" + monthlyBudget} disabled />
                    <label className="switch">
                        <input type="checkbox" data-budgetid={budget.id} onChange={this.handleUniqueBudgetSwitch} checked />
                        <span className="slider round"></span>
                    </label>
                </> :
                <>
                    <input id={budget.id} name={budget.id} type="number" defaultValue={budget.budget.toFixed(2)} onChange={this.handleMonthlyBudgetChange} min="0" step="0.01" />
                    <label className="switch">
                        <input type="checkbox" data-budgetid={budget.id} onChange={this.handleUniqueBudgetSwitch} />
                        <span className="slider round"></span>
                    </label>
                </>;

            uniqueMonthlyBudgets.push(
                <>
                    <label>{monthWords[budget.month - 1]} {budget.year}: </label>
                    <div className='unique-budget-input-container'>{budgetInputs}</div>
                </>
            );
        }

        return (
            <div className='budget-settings-container'>
                <div className='form-title'>Budget</div>

                <form onSubmit={this.handleSubmit} className='budget-settings-form settings-form dual-grid--offset'>
                    <label>Default Monthly Budget: </label>
                    <input name='monthlyBudget' type='number' defaultValue={monthlyBudget} onChange={this.handleCompanyChange} min="1" step="0.01" required />

                    <label>Unique Monthly Budgets... </label>
                    <div>
                        <label style={{ float: "right", marginTop: "0.5%" }}>DEFAULT</label>
                    </div>

                    {uniqueMonthlyBudgets}

                    <div className='dual-grid__1-2 center-grid'>
                        <button type='submit' className='form-submit-btn'>Save</button>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let token = getCurrentCompanyToken();
        const response = await fetch('Settings/GetBudgetSettings?companyToken=' + token, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        this.setState({
            isReady: true,
            company: data.company,
            monthlyBudgets: data.monthlyBudgets
        });
    }
}

export default BudgetSettings;
