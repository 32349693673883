//This form is used to create new companies and donation forms
// This form should contain the bare minimum to get started with a live request link

import React, { Component } from 'react';
import reCaptchaData from '../../ReCaptchaData';
import ReCAPTCHA from "react-google-recaptcha";
import './forms.css';

export class CreateCompany extends Component {
    static displayName = CreateCompany.name;

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className='create-company-static-container'>
                <div className='header'>For more information please contact us to see how you can sign up.</div>

                <div className='contact-info-container'>
                    <div className='company-name'>TracSoft, Inc.‎</div>
                    <div>111 12th Street, Suite 200</div>
                    <div>Columbus, GA 31901</div>
                    <div>(706) 568-4133</div>
                    <div>info@tracsoft.com</div>
                </div>
            </div>
        );
    }
}


//export class CreateCompany extends Component {
//    static displayName = CreateCompany.name;

//    constructor(props) {
//        super(props);
//        this.state = {
//            isReady: false,
//            captchaToken: null,
//            company: null,
//            requestFormSettings: null,
//            requestDesignations: []
//        };
//    }

//    async componentDidMount() {
//        this.getComponentData();
//    }

//    handleCompanyChange = (e) => {
//        let company = this.state.company;
//        company[e.target.name] = e.target.value;
//        this.setState({ company: company });
//    }

//    handleRequestFormSettingsChange = (e) => {
//        let requestFormSettings = this.state.requestFormSettings;
//        requestFormSettings[e.target.name] = e.target.value;
//        this.setState({ requestFormSettings: requestFormSettings });
//    }

//    handleDesignationChange = (designations) => {
//        this.setState({ requestDesignations: designations });
//    }

//    handleSubmit = (e) => {
//        e.preventDefault();

//        let company = this.state.company;
//        let requestFormSettings = this.state.requestFormSettings;

//        let companyData = {
//            Company: company,
//            RequestFormSettings: requestFormSettings,
//            RequestDesignations: this.state.requestDesignations
//        };

//        //Verify captcha before submit
//        if (this.state.captchaToken != null) {
//            fetch('Captcha/IsReCaptchValid?captchaToken=' + this.state.captchaToken, {
//                method: 'POST',
//                headers: {
//                    'Content-Type': 'application/json',
//                    'Accept': 'application/json'
//                }
//            }).then(
//                response => response.json()
//            ).then(
//                data => {
//                    //Add user to 2FA (either add sep local validation fetch before 2FA and local save or insert 2FA into local backend after local validation...(There will also be this validation issue when contacting Stripe and setting up the subscription))

//                    if (data == true) {
//                        fetch('Public/CreateCompanyData', {
//                            method: 'POST',
//                            body: JSON.stringify(companyData),
//                            headers: {
//                                'Content-Type': 'application/json',
//                                'Accept': 'application/json'
//                            }
//                        }).then(
//                            response => response.json()
//                        ).then(
//                            data => {
//                                if (data.includes(".com")) {
//                                    alert("You have successfully created a DonateQuest account. Your unique donation request form URL is: " + data + "You will now be redirected to log into the DonateQuest portal.");
//                                    window.location = "/";
//                                }
//                                else {
//                                    alert(data);
//                                }
//                            }
//                        );
//                    }
//                    else {
//                        alert("reCaptcha verification failed");
//                    }
//                }
//            );
//        }
//        else {
//            alert("reCaptcha verification failed");
//        }
//    }

//    onCaptchaChange = (value) => {
//        if (value) {
//            this.setState({ captchaToken: value });
//        }
//        else {
//            this.setState({ captchaToken: null });
//        }
//    }

//    render() {
//        let companyName;
//        let adminEmail;
//        if (this.state.company != null) {
//            let company = this.state.company;
//            companyName = company.companyName;
//            adminEmail = company.adminEmail;
//        }

//        let friendlyUrl;
//        if (this.state.requestFormSettings != null) {
//            let requestFormSettings = this.state.requestFormSettings;
//            friendlyUrl = requestFormSettings.friendlyUrl;
//        }

//        let designationInput = this.state.requestFormSettings != null ?
//            <RequestDesignationsField
//                designations={[]}
//                designationUpdate={this.handleDesignationChange}
//            /> : "";

//        return (
//            <div className='create-company-container'>

//                <form onSubmit={this.handleSubmit} className='dual-grid--offset form-container'>
//                    <div className='dual-grid__1-2 center-grid'>
//                        <div className='form-title'>Donation Request Company Application</div>
//                    </div>

//                    <div className='dual-grid__1-2 form-sub-title'>Company Details</div>

//                    <label>Company Name: </label>
//                    <input name='companyName' type='text' defaultValue={companyName} onChange={this.handleCompanyChange} required />

//                    <label>Admin Email: </label>
//                    <input name='adminEmail' type='text' defaultValue={adminEmail} onChange={this.handleCompanyChange} required />

//                    <div className='dual-grid__1-2 form-sub-title'>Request Form Details</div>

//                    <label>Friendly URL: </label>
//                    <input name='friendlyUrl' type='text' defaultValue={friendlyUrl} onChange={this.handleRequestFormSettingsChange} required />

//                    <label>Designations: </label>
//                    {designationInput}

//                    <label></label>
//                    <div id="reCaptchaWrapper">
//                        <ReCAPTCHA sitekey={reCaptchaData.getSiteKey()} onChange={this.onCaptchaChange} />
//                    </div>

//                    <div className='dual-grid__1-2 center-grid'>
//                        <button type='submit' className='form-submit-btn'>Submit</button>
//                    </div>
//                </form>
//            </div>
//        );
//    }

//    async getComponentData() {
//        const response = await fetch('Public/GetCompanyData', {
//            method: 'GET',
//            headers: {
//                'Content-Type': 'application/json',
//                'Accept': 'application/json'
//            }
//        });

//        const data = await response.json();

//        this.setState({
//            isReady: true,
//            company: data.company,
//            requestFormSettings: data.requestFormSettings
//        });
//    }
//}
