import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getLoggedInUser } from '../Authentication';
import { getCurrentCompanyToken } from '../CurrentCompanyServices';
import AdminDashboard from '../Admin/AdminDashboard/AdminDashboard';

export default class AuthorizeAdminRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            loggedInUser: null,
            currentCompanyToken: null
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        if (!this.state.isReady) {
            return <div></div>;
        }
        else {
            const redirectUrl = '/login';
            const { component: Component, ...rest } = this.props;
            const navbarParentUrl = this.props.navbarParentUrl;

            return <Route {...rest}
                render={(props) => {
                    //console.log("AuthorizeAdminRoute", this.state.loggedInUser);
                    if (this.state.loggedInUser != null && this.state.currentCompanyToken != null) {
                        let path = props.match.path;
                        return <AdminDashboard component={<Component {...props} />} navbarParentUrl={navbarParentUrl} /> // Continue path
                    } else {
                        return <Redirect to={redirectUrl} />
                    }
                }}
            />
        }
    }

    async getComponentData() {
        let loggedInUser = await getLoggedInUser();
        if (loggedInUser != null) loggedInUser = JSON.parse(loggedInUser);

        let currentCompanyToken = getCurrentCompanyToken();

        this.setState({
            isReady: true,
            loggedInUser: loggedInUser,
            currentCompanyToken: currentCompanyToken
        });
    }
}
