import React, { Component } from 'react';
import './publicdashboard.css';

export class PublicDashboard extends Component {
    static displayName = PublicDashboard.name;

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className='public-dashboard-container'>
                <div className='public-navbar'>
                    <div className='navbar-container'>
                        <a href='/' className='company-name'>Donate Quest</a>

                        <div className='nav-link-container'>
                            <a href='/create-company'>Apply</a>
                            <a href='login'>Login</a>
                        </div>
                    </div>
                </div>

                <div className='public-page-content'>{this.props.component}</div>

                <div className='public-footer'>
                    <span>Powered By <a href='https://tracsoft.com/'>TracSoft</a></span>
                </div>
            </div>  
        );
    }
}

export default PublicDashboard;
