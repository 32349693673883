import React, { Component } from 'react';
import './publicpage.css';

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className='public-page-container home-container'>
                <p>Whether it's Little League teams or nonprofits, your business receive hundreds of requests for donations each month. Supporting local organizations is an important way to stay engaged with your community, but managing all the donations your business makes can be a hassle.</p>

                <p>Make it simple with DonateQuest, a donation management system from TracSoft.</p>

                <div className='features-container'>
                    <h3>Automate Donation Requests</h3>
                    <p>Instead of handling donation requests as you're working, have organizations submit their requests online to a secure system where you can approve themmanually or let the system handle it automatically. Embed your donation request form right on your website so it's easy to find.</p>

                    <h3>Set (and stick to) a Donation Budget</h3>
                    <p>Dozens of small donations add up fast. Use DonateQuest to allocate a budgetfor donations at the beginning of each month. When your budget is spent, you can stop receiving donation requests until your budget resets at the startof the next month. Improve your cash flow while still contributing to your community.</p>

                    <h3>Track Your Contributions</h3>
                    <p>Spontaneous giving is hard to track, but DonateQuest makes it easy to keep highly-accurate accounts of each donation. Keep your books balanced, make tax time easier, and ensure your giving is fair using detailed reports.</p>

                    <h3>Get Funds to Those Who Need Them</h3>
                    <p>Just because you set aside money for worthy causes doesn't mean those causes know the money is available. Embed DonateQuest right on your website, link to it from your social accounts, and let your community know you're ready to support them.</p>
                </div>

                <p>You care about your community. You want to give. So let TracSoft's DonateQuest make this process faster, easier, and more secure. Our donation management system is simple to use and can be set up in under half an hour. Best of all, our web-based platform is backed by our over 20 years of experience and stellar client reviews.</p>

                <p className='center'>We are TracSoft - bringing together technology and partnership to create custom solutions for your business.</p>
            </div>  
        );
    }
}

export default Home;
