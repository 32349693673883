//This is where all requests will be displayed in a table
import React, { Component } from 'react';
import './requests.css';
import { getCurrentCompanyToken } from '../../CurrentCompanyServices';
import { MDBDataTable } from 'mdbreact';
import { Popup } from '../../Popup/Popup';
import services from '../../Services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';

export class Requests extends Component {
    static displayName = Requests.name;

    constructor(props) {
        super(props);

        let today = new Date();

        this.state = {
            isReady: false,
            requestObjs: [],
            statusFilter: "all",
            popup: null,
            defaultMonthlyBudget: 0.00,
            monthlyBudgets: [],
            calcMonth: today.getMonth() + 1,
            calcYear: today.getFullYear()
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    updateRequestStatus = (requestId, newStatus) => {
        //Add comment field that is sent to requestee email and saved to request in db
        let decisionComments = window.prompt("Send a request related comment (leave blank if none):");

        let confirmStatusChange = window.confirm("Are you sure you want to " + newStatus + " this request?");
        if (confirmStatusChange) {
            let requestStatusData = {
                RequestId: requestId,
                NewStatus: newStatus,
                AdminDecisionComments: decisionComments
            };

            fetch('Admin/UpdateRequestStatus', {
                method: 'POST',
                body: JSON.stringify(requestStatusData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (!isNaN(data)) {
                        this.closePopup();
                        this.getComponentData();
                    }
                    else {
                        alert(data);
                    }
                }
            );
        }
    }

    openPopup = (request, designation) => {
        let decisionTimestampField;
        if (request.decisionTimestamp.toString() != "0001-01-01T00:00:00") {
            decisionTimestampField = <>
                <label>Decision:</label>
                <span>{services.formatDateTime(request.decisionTimestamp, false, true, false, false)}</span>
            </>;
        }

        let adminDecisionCommentsField;
        if (request.status == "accept" || request.status == "reject") {
            adminDecisionCommentsField = <>
                <label>Decision Comments: </label>
                <span>{request.adminDecisionComments}</span>
            </>;
        }

        let statusBtns;
        if (request.status == "new") {
            statusBtns = <>
                <button className='accept-btn' onClick={this.updateRequestStatus.bind(this, request.id, "accept")}>Accept</button>
                <button className='reject-btn' onClick={this.updateRequestStatus.bind(this, request.id, "reject")}>Reject</button>
            </>;
        }

        let details = <div className='request-details-container'>
            <div className='header'>Details</div>
            
            <label>First Name:</label>
            <span>{request.firstName}</span>

            <label>Last Name:</label>
            <span>{request.lastName}</span>

            <label>Email:</label>
            <span>{request.email}</span>

            <label>Designation:</label>
            <span>{designation.name}</span>

            <label>Price:</label>
            <span>${designation.price.toFixed(2)}</span>

            <label>Status:</label>
            <span>{request.status}</span>

            <label>Creation:</label>
            <span>{services.formatDateTime(request.creationTimestamp, false, true, false, false)}</span>

            <label>Requested:</label>
            <span>{services.formatDateTime(request.requestedDate, false, true, false, false)}</span>

            {decisionTimestampField}

            <label>Comments:</label>
            <span>{request.comments}</span>

            {adminDecisionCommentsField}

            <div className='status-button-row'>
                {statusBtns}
            </div>
        </div>;

        this.setState({
            popup: <Popup form={details} closePopup={this.closePopup} />
        });
    }

    closePopup = () => {
        this.setState({
            popup: null
        });
    }

    setStatusFilter = (status) => {
        this.setState({
            statusFilter: status
        });
    }

    changeCalcDate = (calcDirection) => {
        let calcMonth = this.state.calcMonth;
        let calcYear = this.state.calcYear;

        if (calcDirection == "back") {
            if (calcMonth == 1) {
                calcMonth = 12;
                calcYear = calcYear - 1;
            }
            else {
                calcMonth = calcMonth - 1;
            }
        }
        else if (calcDirection == "forward") {
            if (calcMonth == 12) {
                calcMonth = 1;
                calcYear = calcYear + 1;
            }
            else {
                calcMonth = calcMonth + 1;
            }
        }

        this.setState({
            calcMonth: calcMonth,
            calcYear: calcYear
        });
    }

    render() {
        let statusFilter = this.state.statusFilter;

        let monthWords = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        let tableRows = [];
        let monthlyPrice = 0.00;
        if (this.state.isReady) {
            for (var i = 0; i < this.state.requestObjs.length; i++) {
                let requestObj = this.state.requestObjs[i];
                let request = requestObj.request;
                let designation = requestObj.designation;
                let requestedDate = new Date(request.requestedDate.toString());

                if (statusFilter == "all" || statusFilter == request.status.toLowerCase()) {
                    let designationName = designation != null ? designation.name : request.designation;
                    let designationPrice = designation != null ? designation.price : "";
                    
                    let row = {
                        status: request.status,
                        requestedDate: services.formatDateTime(request.requestedDate, false, true, false, false),
                        name: request.firstName + " " + request.lastName,
                        email: request.email,
                        designation: designationName,
                        price: "$" + designationPrice.toFixed(2),
                        requestDetails: <div className='table-btn' onClick={this.openPopup.bind(this, request, designation)}>Details</div>
                    };
                    tableRows.push(row);
                }

                if (request.status == "accept" && requestedDate.getMonth() + 1 == this.state.calcMonth && requestedDate.getFullYear() == this.state.calcYear) {
                    monthlyPrice += designation.price;
                }
            }
        }

        let table = {
            columns: [
                { label: 'Status', field: 'status' },
                { label: 'Requested Date', field: 'requestedDate' },
                { label: 'Name', field: 'name' },
                { label: 'Email', field: 'email' },
                { label: 'Designation', field: 'designation' },
                { label: 'Price', field: 'price' },
                { label: '', field: 'requestDetails' }
            ],
            rows: tableRows
        };

        let currentMonthlyBudget = this.state.defaultMonthlyBudget;
        for (var i = 0; i < this.state.monthlyBudgets.length; i++) {
            let budget = this.state.monthlyBudgets[i];
            if (budget.month == this.state.calcMonth && budget.year == this.state.calcYear) {
                if (budget.budget > -1) {
                    currentMonthlyBudget = budget.budget;
                }
            }
        }
        let monthlyPriceClasses = monthlyPrice <= currentMonthlyBudget ? 'lower-price' : 'higher-price';

        return (
            <div className='requests-container'>
                {this.state.popup}

                <div className='page-title'>Requests</div>

                <div className='table-filters'>
                    <div className={statusFilter == 'all' ? 'filter-btn current-filter' : 'filter-btn'} onClick={this.setStatusFilter.bind(this, "all")}>All</div>
                    <div className={statusFilter == 'new' ? 'filter-btn current-filter' : 'filter-btn'} onClick={this.setStatusFilter.bind(this, "new")}>New</div>
                    <div className={statusFilter == 'accept' ? 'filter-btn current-filter' : 'filter-btn'} onClick={this.setStatusFilter.bind(this, "accept")}>Accept</div>
                    <div className={statusFilter == 'reject' ? 'filter-btn current-filter' : 'filter-btn'} onClick={this.setStatusFilter.bind(this, "reject")}>Reject</div>
                </div>

                <div className='calculations'>
                    <div className='calc-month-container'>
                        <span className='calc-arrow' onClick={this.changeCalcDate.bind(this, "back")}><FontAwesomeIcon icon={faArrowAltCircleLeft} /></span>
                        <span>{monthWords[this.state.calcMonth - 1]}</span> <span>{this.state.calcYear}</span>
                        <span className='calc-arrow' onClick={this.changeCalcDate.bind(this, "forward")}><FontAwesomeIcon icon={faArrowAltCircleRight} /></span>
                    </div>

                    <span><label className='calc-label'>Monthly Total: </label><span className={monthlyPriceClasses}>${monthlyPrice.toFixed(2)}</span></span>
                    <span><label className='calc-label'>Monthly Budget: </label><span>${currentMonthlyBudget.toFixed(2)}</span></span>
                </div>

                <div className='table-container'>
                    <MDBDataTable entries={100} data={table} />
                </div>
            </div>
        );
    }

    async getComponentData() {
        let token = getCurrentCompanyToken();

        const response = await fetch('Admin/GetRequests?companyToken=' + token, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        let requests = data.requests;
        let requestDesignations = data.requestDesignations;
        let requestObjs = [];
        for (var i = 0; i < requests.length; i++) {
            let request = requests[i];
            let hasDes = false;
            for (var j = 0; j < requestDesignations.length; j++) {
                let requestDesignation = requestDesignations[j];
                
                if (request.designation == requestDesignation.id.toString()) {
                    hasDes = true;
                    requestObjs.push({
                        request: request,
                        designation: requestDesignation
                    });
                }
            }

            if (!hasDes) {
                requestObjs.push({
                    request: request,
                    designation: null
                });
            }
        }

        this.setState({
            isReady: true,
            requestObjs: requestObjs,
            defaultMonthlyBudget: data.defaultMonthlyBudget,
            monthlyBudgets: data.monthlyBudgets
        });
    }
}
