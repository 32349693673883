import React, { Component } from 'react';
import { getCurrentCompanyToken } from '../../../CurrentCompanyServices';
import HtmlEditorField from '../../../HtmlEditorField';

export class EmailSettings extends Component {
    static displayName = EmailSettings.name;

    constructor(props) {
        super(props);
        this.state = {
            isReady: false,
            requestFormSettings: null
        };
    }

    async componentDidMount() {
        this.getComponentData();
    }

    handleRequestFormSettingsChange = (e) => {
        let requestFormSettings = this.state.requestFormSettings;

        requestFormSettings[e.target.name] = e.target.value;

        this.setState({ requestFormSettings: requestFormSettings });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let token = getCurrentCompanyToken();

        let requestFormSettings = this.state.requestFormSettings;

        let emailSettingsData = {
            companyToken: token,
            requestFormSettings: requestFormSettings,
        }

        fetch('Settings/UpdateEmailSettings', {
            method: 'POST',
            body: JSON.stringify(emailSettingsData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(
            response => response.json()
        ).then(
            data => {
                if (!isNaN(data)) {
                    alert("Saved");
                }
                else {
                    alert(data);
                }
            }
        );
    }

    sendRequestEmailPreview = (emailType) => {
        let approvedEmailType = null;
        if (emailType == "creation" || emailType == "accept" || emailType == "reject") {
            approvedEmailType = emailType;
        }

        if (approvedEmailType != null) {
            let token = getCurrentCompanyToken();
            let requestFormSettings = this.state.requestFormSettings;

            let emailSettingsData = {
                requestFormSettings: requestFormSettings,
                companyToken: token,
                action: approvedEmailType
            }

            fetch('Settings/SendRequestPreviewEmail', {
                method: 'POST',
                body: JSON.stringify(emailSettingsData),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(
                response => response.json()
            ).then(
                data => {
                    if (!isNaN(data)) {
                        alert("Email sent");
                    }
                    else {
                        alert("Error");
                    }
                }
            );
        }
    }

    render() {
        let creationEmailSubject;
        let creationEmailBody;
        let acceptEmailSubject;
        let acceptEmailBody;
        let rejectEmailSubject;
        let rejectEmailBody;
        if (this.state.requestFormSettings != null) {
            let requestFormSettings = this.state.requestFormSettings;
           
            creationEmailSubject = requestFormSettings.creationEmailSubject;
            creationEmailBody = requestFormSettings.creationEmailBody;
            acceptEmailSubject = requestFormSettings.acceptEmailSubject;
            acceptEmailBody = requestFormSettings.acceptEmailBody;
            rejectEmailSubject = requestFormSettings.rejectEmailSubject;
            rejectEmailBody = requestFormSettings.rejectEmailBody;
        }

        return (
            <div className='email-settings-container'>
                <div className='form-title'>Email</div>

                <form onSubmit={this.handleSubmit} className='email-settings-form settings-form dual-grid--offset'>
                    <label>Creation Email Subject: </label>
                    <input name='creationEmailSubject' type='text' defaultValue={creationEmailSubject} onChange={this.handleRequestFormSettingsChange} />

                    <div>
                        <label>Creation Email Body: </label>
                        <span className='preview-email-btn' title='Send request creation email to your default admin email.' onClick={this.sendRequestEmailPreview.bind(this, "creation")}>Preview Email</span>
                    </div>
                    <HtmlEditorField name='creationEmailBody' content={creationEmailBody} handleEditorChange={this.handleRequestFormSettingsChange} />

                    <label>Accepted Email Subject: </label>
                    <input name='acceptEmailSubject' type='text' defaultValue={acceptEmailSubject} onChange={this.handleRequestFormSettingsChange} />

                    <div>
                        <label>Accepted Email Body: </label>
                        <span className='preview-email-btn' title='Send request accepted email to your default admin email.' onClick={this.sendRequestEmailPreview.bind(this, "accept")}>Preview Email</span>
                    </div>
                    <HtmlEditorField name='acceptEmailBody' content={acceptEmailBody} handleEditorChange={this.handleRequestFormSettingsChange} />

                    <label>Rejected Email Subject: </label>
                    <input name='rejectEmailSubject' type='text' defaultValue={rejectEmailSubject} onChange={this.handleRequestFormSettingsChange} />

                    <div>
                        <label>Rejected Email Body: </label>
                        <span className='preview-email-btn' title='Send request rejection email to your default admin email.' onClick={this.sendRequestEmailPreview.bind(this, "reject")}>Preview Email</span>
                    </div>
                    <HtmlEditorField name='rejectEmailBody' content={rejectEmailBody} handleEditorChange={this.handleRequestFormSettingsChange} />

                    <div className='dual-grid__1-2 center-grid'>
                        <button type='submit' className='form-submit-btn'>Save</button>
                    </div>
                </form>
            </div>
        );
    }

    async getComponentData() {
        let token = getCurrentCompanyToken();
        const response = await fetch('Settings/GetEmailSettings?companyToken=' + token, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        });
        const data = await response.json();

        this.setState({
            isReady: true,
            requestFormSettings: data.requestFormSettings
        });
    }
}

export default EmailSettings;
