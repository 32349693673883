import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import './popup.css';

export class Popup extends Component {
    static displayName = Popup.name;

    constructor(props) {
        super(props);
        this.state = {
            form: this.props.form
        };
    }

    closePopup() {
        this.props.closePopup();
    }

    render() {
        return (
            <div className='popup-wrapper'>
                <div className='popup-container'>
                    <div className='popup-content'>
                        <div className='popup-header'>
                            <button className='popup-close-btn' onClick={this.closePopup.bind(this)}>
                                <FontAwesomeIcon icon={faTimesCircle} />
                            </button>
                        </div>

                        <div className='popup-body'>
                            {this.state.form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Popup;