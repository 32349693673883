import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PublicDashboard from '../Public/PublicDashboard/PublicDashboard';

//This is for pages that do not require 2FA but are inside of the base site
export default class AuthorizeRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isReady: false
        };
    }

    componentDidMount() {
        this.getComponentData();
    }

    render() {
        if (!this.state.isReady) {
            return <div></div>;
        }
        else {
            const redirectUrl = '/';
            const { component: Component, ...rest } = this.props;

            return <Route {...rest}
                render={(props) => {
                    return <PublicDashboard component={<Component {...props} />} />;
                }}
            />
        }
    }

    async getComponentData() {
        this.setState({
            isReady: true
        });
    }
}