import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Public/Home';
import { Requests } from './components/Admin/Requests/Requests';
import { Settings } from './components/Admin/Settings/Settings';
import { CompanySelection } from './components/Admin/CompanySelection/CompanySelection';
import { CreateCompany } from './components/Public/Forms/CreateCompany';
import { RequestDonation } from './components/Public/Forms/RequestDonation';
import { Login } from './components/Public/Login/Login';

import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import AuthorizeAdminRoute from './components/api-authorization/AuthorizeAdminRoute';
import AuthorizeTSAdminRoute from './components/api-authorization/AuthorizeTSAdminRoute';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                    <AuthorizeTSAdminRoute path='/company-selection' component={CompanySelection} navbarParentUrl='company-selection' />

                    <AuthorizeAdminRoute path='/requests' component={Requests} navbarParentUrl='requests' />
                    <AuthorizeAdminRoute path='/settings' component={Settings} navbarParentUrl='settings' />

                    <AuthorizeRoute path='/create-company' component={CreateCompany} />
                    <AuthorizeRoute path='/login' component={Login} />
                    <AuthorizeRoute exact path='/' component={Home} />

                    
                    <Route path='/:friendlyUrl' component={RequestDonation} />
                </Switch>
            </Layout>
        );
    }
}
